
import { defineComponent } from "vue-demi";
import InfoOffer from "@/components/info/Offer.vue";

export default defineComponent({
  name: "InfoOfferView",
  components: {
    InfoOffer,
  },
});
